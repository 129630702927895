/* eslint-disable */
import React, { useContext, useEffect, useState } from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"

import Admin from "./pages/litle/Admin/Admin"
import NotFound from "./pages/NotFound"
import Report from "./pages/litle/Mission/Report/Report"
import Offer from "./pages/litle/Offer/Offer"
import { authContext } from "./service/api/authContext"
import { checkAuthentication } from "./service/api/api"
import "./i18n/config"
import Provision from "./pages/litle/Provision/Provision"
import "moment/locale/fr"
import Custom from "./pages/Custom/Custom";
import GraphicDemo from "./pages/Demo/Graphic/GraphicDemo";

const AppRouter = (): React.JSX.Element => {
	const [ allowed, setAllowed ] = useState<boolean>(false)
	const { login, grantAdmin } = useContext(authContext)

	useEffect(() => {
		const handleMessage = async(event: MessageEvent) => {
			if(event.origin === process.env.REACT_APP_TRUST_DOMAIN) {
				if(typeof event.data?.token === "string") {
					login(event.data?.token)
					const response = await checkAuthentication(event.data!.token)
					grantAdmin(response.isAdmin)
					setAllowed(response.isAuthenticated)
					return
				}
				setAllowed(false)
			}
		}

		window.addEventListener("message", handleMessage)

		return () => {
			window.removeEventListener("message", handleMessage)
		}
	}, [allowed])

	document.documentElement.style.setProperty("--primary", "#ffc32d")
	document.documentElement.style.setProperty("--primaryDark", "#d4a124")
	document.documentElement.style.setProperty("--white", "#ffffff")
	document.documentElement.style.setProperty("--whiteRGB", "255, 255, 255")
	document.documentElement.style.setProperty("--black", "#000000")
	document.documentElement.style.setProperty("--third", "#111328")
	document.documentElement.style.setProperty("--second", "#1e2837")
	document.documentElement.style.setProperty("--neutral", "#525f7f")
	document.documentElement.style.setProperty("--neutralLight", "#e7e7f1")
	document.documentElement.style.setProperty("--none", "#ced4da")
	document.documentElement.style.setProperty("--valid", "#1aae6f")
	document.documentElement.style.setProperty("--validLight", "#b0eed3")
	document.documentElement.style.setProperty("--warning", "#F99716")
	document.documentElement.style.setProperty("--warningRGB", "249,151,22")

	return <>
		<Router>
			<Routes>
				<Route path="/offer" element={<Offer/>}/>
				<Route path="/provision" element={<Provision/>}/>
				<Route path="/custom" element={<Custom/>}/>
				{/*<Route path="/graphicChart" element={<GraphicDemo/>}/>*/}
				{allowed && <Route path="/admin" element={<Admin/>}/>}
				{allowed && <Route path="/mission/:id/report" element={<Report/>}/>}
				<Route path="*" element={<NotFound/>}/>
			</Routes>
		</Router>
	</>
}

export default AppRouter
